/* uploadInput.css */
body,
html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.file-previews-container {
  display: flex;
  flex-wrap: wrap;
  width: 430px;
  margin-bottom: 10px;
  overflow: hidden; /* Ensure content doesn't overflow during height transition */
}

.file-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90px;
  height: 90px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 12px;
  border: 0.845px solid #e7e7e7;
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
}

.file-preview img.file-image {
  max-width: 85px;
  max-height: 85px;
  border-radius: 4px;
  overflow: hidden;
}

.file-document {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  /* gap: 5px; */
  margin: 0;
}

.file-document span {
  margin-top: 5px;
  font-size: 12px;
  width: 75px; /* Ensure the span has a fixed width */
  white-space: nowrap;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-document svg {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  color: #000;
}

.remove-button {
  background: none;
  border: 0.845px solid #e7e7e7;
  border-radius: 500px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #888;
}

.input-container {
  display: flex;
  align-items: center;
  width: auto;
  padding: 5px 0;
  margin: 0;
}

.file-upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border: 0.887px solid rgba(46, 46, 46, 0.11);
  cursor: pointer;
  border-radius: 633.333px;
  padding: 5.6px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 425px;
}

.text-input {
  display: flex;
  width: 100%;
  padding: 10px 31px 10px 7px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  flex: 1;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #f9f9f9;
}

.input-underline {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0.8px;
  background-color: rgba(0, 0, 0, 0.45);
  transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  transform: translateX(-50%);
}

.text-input:hover + .input-underline,
.text-input:focus + .input-underline {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.send-button {
  display: flex;
  padding: 5.6px;
  justify-content: center;
  align-items: center;
  border-radius: 633.333px;
  border: 0.887px solid rgba(46, 46, 46, 0.11);
  background: #f9f9f9;
  cursor: pointer;
}

.send-button svg,
.file-upload-button svg {
  font-size: 20px;
  color: #000;
}
