.auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100svh; 
    box-sizing: border-box;
}

.auth-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
}

.auth-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    padding-top: 10rem;
}

.auth-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap; /* Added to handle small screens */
}

.auth-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 0.6rem;
    box-sizing: border-box;
}

.footer-txt {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    letter-spacing: -0.5px;
}

.send-btn {
    position: relative;
    border-radius: 633.33px;
    background-color: #f9f9f9;
    border: 0.9px solid rgba(46, 46, 46, 0.11);
    box-sizing: border-box;
    overflow: hidden;
    /* flex-shrink: 0; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.475rem;
    cursor: pointer;
}

.auth-form-input {
    max-width: 200px;
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.625rem 1.937rem 0.625rem 0.437rem;
    text-align: left;
    font-size: 0.875rem;
}

.input-container {
    width: 220px;
    gap: 0.5rem;
}

.auth-image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.auth-logo-container {
    position: relative;
}

.auth-logo {
    height: 32px;
}

.footer-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-link img.logo {
    height: 32px;
}
