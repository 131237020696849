.question-content {
    /* font-family: "Arial", sans-serif; */
    line-height: 1.6;
    padding: 1rem;
    background-color: #f9f9f9;
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    max-width: 800px;
    margin: 2rem auto;
  }
  
  .question-content h2 {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .question-content p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .question-content ol {
    padding-left: 1.5rem;
  }
  
  .question-content li {
    margin-bottom: 0.5rem;
  }
    .scaled-down-content {
      transform: scale(0.8); /* Example scale factor */
      transform-origin: top left;
    }
    
    .scaled-down-text {
      font-size: 0.8em; /* Example reduced font size */
    }