html, body {
    margin: 0;
    height: 100%;
    padding: 0;
    background: #f9f9f9;
    font-family: Inter, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Segoe UI", Helvetica, Helvatica Neue, Arial, "Apple Color Emoji", "Segoe UI Emoji";
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    /* overflow: hidden;  */
    align-items: center;
  }
  /* body::-webkit-scrollbar {
    display: none;
   */

  /* Make the scrollbar visible */
body::-webkit-scrollbar {
  width: 9px; /* Width of the vertical scrollbar */
  height: 9px; /* Height of the horizontal scrollbar */
}

/* Customize the scrollbar track */
body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 500px; /* Rounded corners */
  border: 1px solid #e0e0e0; /* Border around the track */
}

/* Customize the scrollbar thumb */
body::-webkit-scrollbar-thumb {
  background: #e0e0e0; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #f1f1f1; /* Border around the thumb */
}

/* Customize the scrollbar thumb on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0; /* Thumb color on hover */
}

/* For Firefox */
body {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #e0e0e0 #f1f1f1; /* Thumb and track color */
}


  
  /* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } */
  
  /* body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin: 0;
    padding: 0;

    height: 100vh;
    background-color: #f9f9f9;
  } */
  *{

    margin-block: 0;  
  }
  .App {
    /* text-align: center; */
    display: flex;
    /* overflow-x: hidden; */
    margin-block: 0;
    height: 100svh;
    width: 98.5svw;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  