.sidebar {
    display: flex;
    width: 17%;
    padding-top: 25px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    margin-right: auto;
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2); */
    background-color: hsla(0, 0.00%, 100.00%, 1.00);
    box-shadow: 0px 206px 58px 0px rgba(0, 0, 0, 0.00), 0px 132px 53px 0px rgba(0, 0, 0, 0.01), 0px 74px 45px 0px rgba(0, 0, 0, 0.05), 0px 33px 33px 0px rgba(0, 0, 0, 0.09), 0px 8px 18px 0px rgba(0, 0, 0, 0.10);
}

.class-sidebar-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Changed to center to properly align items vertically */
    justify-content: flex-start; /* Changed to flex-start for proper alignment */
    gap: 0.2rem;
    width: 100%;
    /* padding-top: 0.312rem; */
    cursor: pointer; /* Add pointer cursor to indicate clickable area */
    transition: background-color 0.2s ease; /* Add smooth background transition */
}

.class-sidebar-header:hover {
    background-color: #f9f9f9; /* Light gray background on hover */
    border-radius: 10px; /* Optional: round corners on hover */
}

.class-siderbar-header-chevron {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 0.25rem 0rem 0rem; */
    padding-top: 7px;
    /* box-sizing: border-box; */
    /* font-weight: 200; */
    color: rgba(0, 0, 0, 0.45); /* Ensure the chevron color matches text */
}

.class-sidebar-header-title-wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0.312rem 0rem 0.625rem 0.312rem;
    box-sizing: border-box;
    text-align: left;
    font-size: 0.75rem;
    gap: 4px;   
    color: rgba(0, 0, 0, 0.45);
}

.class-sidebar-header-title-head {
    font-size: 0.875rem;
    letter-spacing: -0.4px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.50);
    text-align: left;
    margin-block: 0;
}

.class-sidebar-header-title-subhead {
    font-size: 1rem;
    letter-spacing: -0.02em;
    font-weight: 500;
    color: #000;
    text-align: left;
    margin-block: 0;
}

.class-sidebar-body {
    flex: 1;
}

.class-sidebar-footer {
    display: flex;
    align-items: center;
    margin-top: 50px;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 22;
    padding: 20px 15px;

}
.class-siderbar-footer-profile-img {
    margin-right: 8px;
}

.class-sidebar-footer-profile-wrap {
    display: flex;
    flex-direction: column;
}

.class-sidebar-footer-profile-small {
    font-size: 14px;
    margin-block: 0;
    text-align: left;
}

.class-sidebar-footer-profile-smaller {
    font-size: 12px;
    color: gray;
    margin-block: 0;
    text-align: left;
}
