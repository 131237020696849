.display-body {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 19px;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    grid-column-gap: 49px;
    grid-row-gap: 49px;
  }