.class-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.8rem;
    font-size: 0.739rem;
    color: #000;
    height: auto;
}

.class-body-section {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.625rem;
}

.h2-small {
    color: hsla(0, 0.00%, 0.00%, 0.60);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-block: 0;
}

.section-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.825rem;
    max-height: 440px;
    overflow: hidden;
    position: relative;
}

.questions-section {
    max-height: 440px;
    padding-top: 20px;  
    overflow-y: auto;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.875rem;
    padding-right: 8px; /* Adjust to prevent content from being cut off */
}

.gradient-blur {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 20;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}


.gradient-blur-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    margin-top: 18px;
    z-index: 20;
    pointer-events: none;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.section-header {
    padding-left: 8px;
}

/* add media query for bigger displays */
@media (min-height: 900px) {
    .class-body {
        font-size: 0.875rem;
    }

    .section-2 {
        max-height: 600px;
    }

    .questions-section {
        max-height: 600px;
    }
}
