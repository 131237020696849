/* General styles for the upload input container */
.uploads-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    height: auto;
    justify-content: center; /* Centering the container vertically */
  }
  
  /* Styles for the container holding file previews */
  .file-previews-container {
    display: flex;
    flex-wrap: wrap;
    width: 430px;
    margin-bottom: 10px;
    padding-left: 5px;
    overflow: hidden; /* Ensure content doesn't overflow during height transition */
  }
  
  /* Styles for each file preview */
  .file-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90px;
    height: 90px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 12px;
    border: 0.845px solid #e7e7e7;
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  }
  
  /* Styles for file images within the preview */
  .file-preview img.file-image {
    max-width: 85px;
    max-height: 85px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  /* Styles for file documents within the preview */
  .file-document {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  
  /* Styles for the file name within the preview */
  .file-document span {
    margin-top: 5px;
    font-size: 12px;
    width: 75px; /* Ensure the span has a fixed width */
    white-space: nowrap;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Styles for the file icon within the preview */
  .file-document svg {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    color: #000;
  }
  
  /* Styles for the remove button within the preview */
  .remove-button {
    background: none;
    border: 0.845px solid #e7e7e7;
    border-radius: 500px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    color: #888;
  }
  
  /* Styles for the input container */
  .input-container {
    display: flex;
    align-items: center;
    width: auto;
    padding: 5px 0;
    margin: 0;
    justify-content: center; /* Centering the input container */
  }
  
  /* Styles for the file upload button */
  .file-upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border: 0.887px solid rgba(46, 46, 46, 0.11);
    cursor: pointer;
    border-radius: 633.333px;
    padding: 5.6px;
  }
  
  /* Styles for the input wrapper */
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 425px;
  }
  
  /* Styles for the text input */
  .text-input {
    display: flex;
    width: 100%;
    padding: 10px 31px 10px 7px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    flex: 1;
    border: none;
    outline: none;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #f9f9f9;
  }
  
  /* Styles for the input underline */
  .input-underline {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0.8px;
    background-color: rgba(0, 0, 0, 0.45);
    transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  }
  
  /* Hover and focus effects for the text input */
  .text-input:hover + .input-underline,
  .text-input:focus + .input-underline {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Styles for the send button */
  .send-button {
    display: flex;
    padding: 5.6px;
    justify-content: center;
    align-items: center;
    border-radius: 633.333px;
    border: 0.887px solid rgba(46, 46, 46, 0.11);
    background: #f9f9f9;
    cursor: pointer;
  }
  
  /* Icon styles for the send button and file upload button */
  .send-button svg,
  .file-upload-button svg {
    font-size: 20px;
    color: #000;
  }
  
  .absolute-container {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  