.class-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100svh;

}

.class-auth-header {
    width: 100svw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1.6rem;
    padding-left: 1.6rem;

}

.logo-container {
    position: relative;
    /* display: inline-block; */
}

.logo {
    height: 26px;
    /* margin-right: 10px; */
}

.image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: transparent; */
    /* pointer-events: none;  */
}

.class-auth-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    padding-top: 7rem;
}

h1 {
    font-size: 2rem;
    letter-spacing: -2px;
    margin-bottom: 1rem;
    font-weight: 600;
    /* margin-block:0; */

  }

.class-auth-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap; /* Added to handle small screens */
}

.input-container {
    position: relative;
    margin-left: auto;
}

.class-auth-form-input {
    border: none;
    outline: none;
    transition: border-bottom 0.3s ease;
    max-width: 220px;
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.625rem 1.937rem 0.625rem 0.437rem;
    text-align: left;
    background-color: #f9f9f9;
    /* font-size: 0.875rem; */
    font-size: 1rem;
    letter-spacing: -0.1px;
}

.class-auth-form-input:hover,
.class-auth-form-input:focus {
    border-bottom: 1px solid #000;
}

.bottom-line {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.class-auth-form-input:focus + .bottom-line,
.class-auth-form-input:hover + .bottom-line {
    transform: scaleX(1);
}

.send-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 10px;
}

.class-auth-footer {
    /* margin-top: 20px; */
    margin-top: auto;
    padding-bottom: 2rem;
}

.footer-link {
    color: #007bff;
    text-decoration: none;
    font-size: 0.875rem;
    /* color: rgba(0, 0, 0, 0.5); */
    text-align: center;
    letter-spacing: -0.2px;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-text {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    letter-spacing: -0.2px;

}

/* .logo {
    position: absolute;
    bottom: -50px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
} */

.footer-link:hover .footer-text {
    opacity: 0;
    transform: translateY(-20px);
}

.footer-link:hover .logo {
    opacity: 1;
    transform: translateY(-20px);
}

