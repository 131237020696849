/* Styles for the visualizer container */
.visualizer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: auto;
    /* padding: 0.6rem; */
  }
  
  .visualizer-container svg {
    max-width: 100%;
    height: 100%;
  }
  