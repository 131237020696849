.frame-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 405px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
  
.controls {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
  
.frame {
    display: flex;
    height: auto;
    min-height: 60%;
    width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 10px 20px 50px 20px; /* Adjust padding */
    white-space: nowrap;
    position: relative;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
  
.frame::-webkit-scrollbar {
    display: none;
}
  
.default-div,
.new-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 83px;
    border: 1px solid rgba(60, 75, 90, 0.5);
    border-radius: 12px;
    box-shadow: 0px 74px 21px 0px rgba(0, 0, 0, 0.00), 0px 48px 19px 0px rgba(0, 0, 0, 0.02), 0px 27px 16px 0px rgba(0, 0, 0, 0.08), 0px 12px 12px 0px rgba(0, 0, 0, 0.13), 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
    /* background: rgb(0, 0, 0); */
    background-color: #f9f9f9;
    flex: 0 0 auto;
    margin-right: 1rem;
    transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
  
.new-div.current {
    border-color: rgba(60, 75, 90, 0.9);
    transform: scale(1.05);
}
  
.new-div:hover:not(.current) {
    border-color: rgba(60, 75, 90, 0.6);
    transform: scale(1.02);
}
  
.frame > :first-child {
    margin-left: 150px; /* Adjust margin */
    /* animation: subtleScaleUp 0.5s ease-in-out; */
}
  
.frame > :last-child {
    margin-right: 150px; /* Adjust margin */
}
  
.create-btn {
    display: flex;
    padding: 0.6875rem 0.9375rem;
    justify-content: center;
    align-items: center;
    gap: 0.31219rem;
    border-radius: 0.43706rem;
    border: 1px solid rgba(10, 132, 255, 0.1);
    background: rgba(10, 132, 255, 0.07);
    color: #0a84ff;
    outline: none;
    text-align: center;
    font-size: 0.8745rem;
    font-weight: 500;
    line-height: 111%;
    letter-spacing: -0.025rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
    margin-top: 1rem;
}
  
.create-btn:hover {
    background: rgba(10, 132, 255, 0.15);
    border: 1px solid rgba(10, 132, 255, 0.2);
}
  
.left-btn,
.right-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    padding: 0.6875rem 0.9375rem;
    justify-content: center;
    align-items: center;
    gap: 0.31219rem;
    border-radius: 0.43706rem;
    border: 1px solid rgba(10, 132, 255, 0.1);
    background: rgba(10, 132, 255, 0.07);
    color: #0a84ff;
    outline: none;
    text-align: center;
    font-size: 0.8745rem;
    font-weight: 500;
    line-height: 111%;
    letter-spacing: -0.025rem;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
    opacity: 0;
    z-index: 10;
}
  
.controls:hover .left-btn,
.controls:hover .right-btn {
    opacity: 1;
}
  
.left-btn {
    left: 10px;
}
  
.right-btn {
    right: 10px;
}
  
.left-btn:hover,
.right-btn:hover {
    background: rgba(10, 132, 255, 0.15);
    border: 1px solid rgba(10, 132, 255, 0.2);
}
  
@keyframes subtleScaleUp {
    from { 
        transform: scale(0.95);
        opacity: 0.9;
    }
    to { 
        transform: scale(1);
        opacity: 1;
    }
}
  
@keyframes scaleUp {
    from { 
        transform: scale(0.5);
        opacity: 0;
    }
    to { 
        transform: scale(1);
        opacity: 1;
    }
}
  
.new-div:not(:first-child):last-child {
    animation: scaleUp 0.5s ease-in-out;
}

  
  button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  