.display {
    display: flex;
    width: 85%;
    height: 100%;
    padding-right: 55px;
    padding-left: 55px;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
  }

.display-header{
    width: 100%;
    padding-left: 0px;
    margin-top: 30px;
}

.headertitle {
    display: flex;
    padding-left: 12px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .h2-m {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }


  /* body element */
  .display-body {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 19px;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    grid-column-gap: 49px;
    grid-row-gap: 49px;
  }


.display-footer {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-top: auto;
    padding-bottom: 17px;
    justify-content: center;
    align-items: center;
}

.input-field {
    display: flex;
    width: 30rem;
    height: auto;
    background-color: #f9f9f9;
    padding-top: 12px;
    padding-right: 10px;
    padding-bottom: 12px;
    padding-left: 10px;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: hsla(0, 0.00%, 0.00%, 0.00);
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: hsla(0, 0.00%, 0.00%, 0.00);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: hsla(0, 0.00%, 0.00%, 0.24);
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: hsla(0, 0.00%, 0.00%, 0.00);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }