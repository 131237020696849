/* History Component Styles */
.history {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 410px;
}

.history-wrap {
    margin-bottom: 25px;
    position: relative;
}

.history-label {
    color: rgba(0, 0, 0, 0.55);
    font-family: "SF Pro Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: left;
}

.history-item-wrap {
    max-height: 300px; /* Adjust as needed */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 410px;
    padding-bottom: 8px;
    scrollbar-width: thin; /* Thin scrollbar for Firefox */
    scrollbar-color: #c1c1c1 #f1f1f1; /* Thumb and track color for Firefox */
}

/* Custom Scrollbar Styles for Webkit Browsers (Chrome, Safari) */
.history-item-wrap::-webkit-scrollbar {
    width: 8px; /* Width of the vertical scrollbar */
    height: 8px; /* Height of the horizontal scrollbar */
}

/* Customize the scrollbar track */
.history-item-wrap::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 500px; /* Rounded corners */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Box shadow */
}

/* Customize the scrollbar thumb */
.history-item-wrap::-webkit-scrollbar-thumb {
    background: #c1c1c1; /* Thumb color */
    border-radius: 500px; /* Rounded corners */
    border: 2px solid #f1f1f1; /* Border around the thumb */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Box shadow */
}

/* Customize the scrollbar thumb on hover */
.history-item-wrap::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8; /* Thumb color on hover */
}

.history-item-container {
    width: auto;
    padding: 0px 8px 0px 8px;
    position: relative;
}

.history-item {
    margin-bottom: 5px;
    margin-right: auto;
    gap: 5px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    position: relative; /* To ensure the delete button is positioned correctly */
    padding: 10px;
    border-radius: 11px;
    width: 350px;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.history-item:hover {
    background-color: #f9f9f9;
    border-radius: 11px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.history-item:active {
    background-color: #f0f0f0;
    border-radius: 11px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.history-item-text {
    /* margin-bottom: 5px; */
    margin-right: auto;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.history-item-title {
    color: rgba(0, 0, 0, 0.40);
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    text-align: left;
}

.history-item-subtitle {
    color: #000;
    font-family: "SF Pro Display";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: left;
    width: 255px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.separator {
    border: none;
    border-bottom: 1px solid lightgray;
    margin: 10px 0;
}

.history-item-img, .history-file-document {
    margin-top: 10px;
    margin-right: 15px;
}

.history-item-img {
    height: 34px;
    width: 36px;
    border-radius: 10px;
    border: 1px solid rgba(46, 46, 46, 0.15);
}

.history-file-document {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 10px;
    border: 0.887px solid rgba(46, 46, 46, 0.11);
    width: 36px;
    height: 34px;
}

.history-file-document span {
    margin-top: 5px;
    font-size: 12px;
    width: 75px;
    white-space: nowrap;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none; /* Ensure span is displayed */
}

.history-file-document svg {
    height: 28px;
}

.more-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #888;
}

.more-options {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    gap: 5px;
}

.option-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #888;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background 0.2s ease-in-out;
}

.option-button:hover {
    background: #f1f1f1;
    color: #000;
}

/* Gradient background at the bottom of the page */
/* .page-gradient {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(to top, rgba(249, 249, 249, 1), rgba(249, 249, 249, 0));
    pointer-events: none;
    z-index: 1000;
} */


.history-empty-state{
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.45);
    letter-spacing: -0.3px;
}

.gradient-blur {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 20;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}