.frame-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 48px;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    overflow: hidden;
  }

  .body-frame {
    width: 38rem;
    height: 21rem;
    position: relative;
    border-radius: 20px;
    /* background-color: hsla(0, 0.00%, 0.00%, 1.00); */
    background-color: #f9f9f9;
    /* border-radius: 17px; */
/* background: url(<path-to-image>) lightgray 50% / cover no-repeat; */
    box-shadow: 0px 74px 21px 0px rgba(0, 0, 0, 0.00), 0px 48px 19px 0px rgba(0, 0, 0, 0.02), 0px 27px 16px 0px rgba(0, 0, 0, 0.08), 0px 12px 12px 0px rgba(0, 0, 0, 0.13), 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
    /* box-shadow: 0 8px 12px 0 hsla(0, 0.00%, 0.00%, 0.28); */
  }

  .body-frame-carousel {
    display: flex;
    /* padding-right: 41px;
    padding-left: 41px; */
    justify-content: center;
    align-items: center;
    /* grid-column-gap: 8px;
    grid-row-gap: 8px; */
    overflow: hidden;
  }


  
  .body-frame-carousel-item.first {
    box-shadow: 0 5px 13px 0 hsla(0, 0.00%, 0.00%, 0.28);
  }

  .body-frame-carousel-item {
    width: 8rem;
    height: 5.6rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 74px 21px 0px rgba(0, 0, 0, 0.00), 0px 48px 19px 0px rgba(0, 0, 0, 0.02), 0px 27px 16px 0px rgba(0, 0, 0, 0.08), 0px 12px 12px 0px rgba(0, 0, 0, 0.13), 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
    background-color: hsla(0, 0.00%, 0.00%, 1.00);
  }
  
  .body-frame-carousel-item.second {
    width: 7.3rem;
    height: 4.9rem;
    box-shadow: 0 5px 13px 0 hsla(0, 0.00%, 0.00%, 0.28);
  }



    /* .body-frame-carousel-item { */
    /* width: 8rem;
    height: 5.6rem; */
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    /* border-radius: 12px; */
/* background: url(<path-to-image>) lightgray 50% / cover no-repeat; */
    /* box-shadow: 0px 74px 21px 0px rgba(0, 0, 0, 0.00), 0px 48px 19px 0px rgba(0, 0, 0, 0.02), 0px 27px 16px 0px rgba(0, 0, 0, 0.08), 0px 12px 12px 0px rgba(0, 0, 0, 0.13), 0px 3px 7px 0px rgba(0, 0, 0, 0.15); */
    /* background-color: hsla(0, 0.00%, 0.00%, 1.00); */
  /* } */



/* Add this to frame.css or a new CSS file */
.scaled-down-frame {
  width: 14rem; /* Adjust the width as needed */
  height: 5.5rem; /* Adjust the height as needed */
  transform: scale(0.8); /* Scale down the frame */
  transform-origin: top left; /* Scale from the top left corner */
  box-shadow: none; /* Optional: adjust shadow or remove it */
  overflow: hidden; /* Ensure content doesn't overflow */
  padding-top: 0;
}

.scaled-down-frame .body-frame-carousel {
  display: flex; 
  width: 100%;
}

.scaled-down-frame .body-frame {
  padding: 0.5rem; /* Adjust padding as necessary */
}
