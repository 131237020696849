/* classBody.css */

.class-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    cursor: pointer;
}

/* Container styles */
.class-preview-item {
    height: 7.356rem;
    width: 190px;
    position: relative;
    /* background-color: hsla(0, 0.00%, 0.00%, 1.00); */
    overflow: hidden;
    border-radius: 9px;
    align-self: center;
    align-items: center;
    border: 0.845px solid #E7E7E7;
    box-shadow: 0px 6.756px 1.689px 0px rgba(0, 0, 0, 0.00), 0px 4.223px 1.689px 0px rgba(0, 0, 0, 0.01), 0px 2.534px 1.689px 0px rgba(0, 0, 0, 0.05), 0px 0.845px 0.845px 0px rgba(0, 0, 0, 0.09), 0px 0px 0.845px 0px rgba(0, 0, 0, 0.10);
    background-position: top;
    text-align: left;
    font-size: 0.748rem;
    color: #000;
}

.class-preview-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 0.5rem; */
    gap: 0.5rem;
    /* width: 100%;
    height: 100%; */
}


.sidebar-body-section-item {
    width: 10.5rem;
    height: 6.35rem;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    background-color: hsla(0, 0.00%, 0.00%, 1.00);
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .class-preview-item:hover {
    /* border: 1px solid #007AFF; */
    border: 1px solid #00000047;
    box-shadow: 0 2px 8px rgba(160, 129, 129, 0.1);
}

.class-preview-item:active {
    /* background-color: #f8f8f8; */
    border: 1px solid #0000009c;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.box-label{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 15;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem;
    font-weight: 500;
}


/* Base overlay style */
/* .class-preview-item-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    pointer-events: none;
    z-index: 50;
} */

h3{
    font-size: 0.875rem;
}

/* Gradient blur */
.gradient-blur-p {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    pointer-events: none;
}
.gradient-blur-p > div,
.gradient-blur-p::before,
.gradient-blur-p::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.gradient-blur-p::before {
    content: "";
    z-index: 1;
    backdrop-filter: blur(0.5px);
    mask: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 12.5%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 0) 37.5%
    );
}
.gradient-blur-p > div:nth-of-type(1) {
    z-index: 2;
    backdrop-filter: blur(1px);
    mask: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 12.5%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 1) 37.5%,
        rgba(255, 255, 255, 0) 50%
    );
}
.gradient-blur-p > div:nth-of-type(2) {
    z-index: 3;
    backdrop-filter: blur(2px);
    mask: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 25%,
        rgba(255, 255, 255, 1) 37.5%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 62.5%
    );
}
.gradient-blur-p > div:nth-of-type(3) {
    z-index: 4;
    backdrop-filter: blur(4px);
    mask: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 37.5%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 62.5%,
        rgba(255, 255, 255, 0) 75%
    );
}
.gradient-blur-p > div:nth-of-type(4) {
    z-index: 5;
    backdrop-filter: blur(8px);
    mask: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 1) 62.5%,
        rgba(255, 255, 255, 1) 75%,
        rgba(255, 255, 255, 0) 87.5%
    );
}
.gradient-blur-p > div:nth-of-type(5) {
    z-index: 6;
    backdrop-filter: blur(16px);
    mask: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 62.5%,
        rgba(255, 255, 255, 1) 75%,
        rgba(255, 255, 255, 1) 87.5%,
        rgba(255, 255, 255, 0) 100%
    );
}
.gradient-blur-p > div:nth-of-type(6) {
    z-index: 7;
    backdrop-filter: blur(32px);
    mask: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 75%,
        rgba(255, 255, 255, 1) 87.5%,
        rgba(255, 255, 255, 1) 100%
    );
}
.gradient-blur-p::after {
    content: "";
    z-index: 8;
    backdrop-filter: blur(64px);
    mask: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 87.5%,
        rgba(255, 255, 255, 1) 100%
    );
}
